<template>
  <div>
    <h2 class=" text-center text-uppercase">epu/chop unit</h2>
    <v-stepper-header>
      <v-stepper-step editable :complete="e1 > 5" step="1" :color="color">
        Account Opening
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :editable="editSteppers" :complete="e1 > 5" step="2" :color="color">
        Demographics Data
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :editable="editSteppers" :complete="e1 > 5" step="3" :color="color">
        Presentation
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :editable="editSteppers" :complete="e1 > 5" step="4" :color="color">
        Treatment And Outcome
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :editable="editSteppers" :complete="e1 > 5" step="5" :color="color">
        Follow up
      </v-stepper-step>
    </v-stepper-header>
  </div>
</template>

<script>
export default {
  name: "EpuStepper",
  data() {
    return {
      e1: 1,
      color: '#024202',
    }
  },

  computed: {
    editSteppers() {
      return true
    },
  },
}
</script>
