<template>
  <div>
    <v-stepper-content step="4">
      <h3 class=" text-center mb-2">Treatment And Outcome</h3>
      <v-card class="mb-8 pa-3" :color="cardColor">

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-textarea label="Admission Diagnosis (es):" auto-grow rows="1" :color="color"
              v-model="dForm.outcome.admissiondiagnosis">
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea label="Final Diagnosis (es):" auto-grow rows="1" :color="color"
              v-model="dForm.outcome.finaldiagnosis">
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-text-field label="Duration of Admission (days):" type="number" :color="color"
              v-model="dForm.outcome.durationadmission">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea label="Drugs Given:" auto-grow rows="1" :color="color" v-model="dForm.outcome.givendrug">
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-textarea label="Outcome:" auto-grow rows="1" :color="color" v-model="dForm.outcome.outcome">
            </v-textarea>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-radio-group dense v-model="dForm.outcome.bloodtransfusion" row>
              <span class="mr-3">Blood Transfusion:</span>
              <v-radio label="Yes" value="Yes" color="green"></v-radio>
              <v-radio label="No" value="No" color="green"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-radio-group dense v-model="dForm.outcome.overalloutcome" row>
              <span class="mr-3">Overall Outcome:</span> <br>
              <v-radio label="Survived" value="Survived" color="green"></v-radio> <br>
              <v-radio label="Survived with sequelae e.g. Acute renal failure,persistent hypertension"
                value="Survivedsequelae" color="green"></v-radio> <br>
              <v-radio label="DAMA" value="DAMA" color="green"></v-radio> <br>
              <v-radio label="Died" value="Died" color="green"></v-radio>

            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>
      <v-card-actions>
        <v-btn color="error" small>
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="saveChanges" :loading="loading" small color="green lighten-1">
          Save and Continue
        </v-btn>
      </v-card-actions>
    </v-stepper-content>
  </div>
</template>

<script>
import { UpdateADoc } from '@/Auth/index';
import { snackbar } from '@/main.js';

export default {
  name: "TreatOutcome",
  props: ['patient', 'patientData', 'hosp_no'],

  data() {
    return {
      color: '#024202',
      cardColor: '#f0f7f0',
      loading: false,

      dForm: {
        outcome: {}
      }
    }
  },
  created() {
    if (this.hosp_no) {
      setTimeout(() => {
        this.dForm = this.patientData
      }, 1000)
    }
  },

  methods: {
    async saveChanges() {
      this.loading = true
      try {
        await UpdateADoc.update("patients", this.patientData.hosp_no, this.dForm)
        this.loading = false
        snackbar.$emit("displaySnackbar", { msg: 'Chnages Saved', color: 'success' })

      } catch (err) {
        this.loading = false
        snackbar.$emit("displaySnackbar", { msg: 'Error Occured', color: 'error' })
       err
      }


    },
  },

}
</script>
