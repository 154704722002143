<template>
  <div>
    <v-stepper-content step="3">
      <h3 class="text-center mb-2">Presentation</h3>
      <v-card class="mb-8" :color="cardColor">
        <v-card-title>
          
        </v-card-title>
        <v-window v-model="window">
          <v-container>
            <v-window-item :value="0">
              <v-row class="compliants">
                <h4 class="mt-4">Complaints At Presentation</h4>
                <v-col cols="12">
                 

                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                      v-for="(ill, index) in dForm.present.illness"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="dForm.present.illness[index].isActive"
                        :label="ill.complaint"
                        :value="ill.isActive"
                        color="green"
                      >
                      </v-checkbox>
                      <v-text-field
                        v-model="ill.duration"
                        label="Duration(days)"
                        color="green"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-checkbox
                        v-model="dForm.present.convulsion.status"
                        label="Convulsion in current illness"
                        color="green"
                      >
                      </v-checkbox>
                      <v-text-field
                        v-model="dForm.present.convulsion.episode"
                        label="No. of episodes(hours)"
                        type="number"
                        color="green"
                      >
                      </v-text-field>
                    </v-col>
                 
                    <v-col class="dflex items-end" cols="12" sm="6" md="3">
                      <v-btn x-small @click="dialog = !dialog">Add Others</v-btn>
                    </v-col>
                 

                    <v-col>
                      <v-radio-group
                        dense
                        v-model="dForm.present.convulsion.seizure"
                        row
                      >
                        <span class="mr-3">Pattern of Seizures: </span>
                        <v-radio
                          label="Generalised"
                          value="Generalised"
                          color="green"
                        ></v-radio>
                        <v-radio
                          label="Focal"
                          value="Focal"
                          color="green"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-checkbox
                        v-model="dForm.present.episode.status"
                        label="Any previous episodes"
                        color="green"
                      >
                      </v-checkbox>
                      <v-text-field
                      v-if="dForm.present.episode.status"
                        v-model="dForm.present.episode.howmany"
                        label="How many episodes"
                        type="number"
                        color="green"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-radio-group
                        v-model="dForm.present.bloodTransfussion"
                        dense
                        row
                      >
                        <span class="mr-3">Blood Transfusion: </span>
                        <v-radio
                          label="Yes"
                          value="Yes"
                          color="green"
                        ></v-radio>
                        <v-radio label="No" value="No" color="green"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="compliants mt-8">
              <h4>CLINICAL SIGNS AT PRESENTATION</h4>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Height/Length(cm):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.height"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Weight(kg):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.weight"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="OFC(cm):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.ofc"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="MUAC(cm):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.muac"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="BMI(kg/m2):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.bmi"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    type="number"
                    :color="color"
                    v-model="dForm.present.temperature"
                  >
                    <template v-slot:label>
                      Temperature(<sup>o</sup>C):
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-radio-group dense v-model="dForm.present.pallor" row>
                    <span class="mr-3">Pallor:</span>
                    <v-radio label="Yes" value="Yes" color="green"></v-radio>
                    <v-radio label="No" value="No" color="green"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-radio-group dense v-model="dForm.present.jaundice" row>
                    <span class="mr-3">Jaundice:</span>
                    <v-radio label="Yes" value="Yes" color="green"></v-radio>
                    <v-radio label="No" value="No" color="green"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-radio-group dense v-model="dForm.present.oedema" row>
                    <span class="mr-3">Oedema:</span>
                    <v-radio label="Yes" value="Yes" color="green"></v-radio>
                    <v-radio label="No" value="No" color="green"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Respiratory Rate(min):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.respiratory"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-radio-group dense v-model="dForm.present.chestin" row>
                    <span class="mr-3">Respiratory Distress:</span>
                    <v-radio label="Yes" value="Yes" color="green"></v-radio>
                    <v-radio label="No" value="No" color="green"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-radio-group dense v-model="dForm.present.lungfield" row>
                    <span class="mr-3">Lung fields:</span>
                    <v-radio
                      label="Normal"
                      value="Normal"
                      color="green"
                    ></v-radio>
                    <v-radio
                      label="Crepitations"
                      value="Crepitations"
                      color="green"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Heart Rate (min):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.heartrate"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Blood Pressure (mm/Hg):"
                    type="text"
                    :color="color"
                    v-model="dForm.present.bloodpressure"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Mumur:"
                    type="number"
                    :color="color"
                    v-model="dForm.present.mumur"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Liver Size (cm):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.liversize"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Spleen size (cm):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.spleensize"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Coma score (GCS):"
                    type="number"
                    :color="color"
                    v-model="dForm.present.comascore"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-radio-group dense v-model="dForm.present.posture" row>
                    <span class="mr-3">Posture:</span>
                    <v-radio
                      label="Normal"
                      value="Normal"
                      color="green"
                    ></v-radio>
                    <v-radio
                      label="Decorticate"
                      value="Decorticate"
                      color="green"
                    ></v-radio>
                    <v-radio
                      label="Decerebrate"
                      value="Decerebrate"
                      color="green"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="4" sm="6" md="4">
                  <span>Power: </span>
                  <v-row>
                    <v-col v-if="dForm.present.power">
                      <v-select
                        v-model="dForm.present.power.rul"
                        v-mode
                        label="RUL"
                        :items="powers"
                        color="green"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="dForm.present.power.lul"
                        label="LUL"
                        :items="powers"
                        color="green"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="dForm.present.power.rll"
                        label="RLL"
                        :items="powers"
                        color="green"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        label="LLL"
                        v-model="dForm.present.power.lll"
                        :items="powers"
                        color="green"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-radio-group dense v-model="dForm.present.tone" row>
                    <span class="mr-3">Tone:</span>
                    <v-radio
                      label="Normal"
                      value="Normal"
                      color="green"
                    ></v-radio>
                    <v-radio
                      label="Hypertonia"
                      value="Hypertonia"
                      color="green"
                    ></v-radio>
                    <v-radio
                      label="Hypotonia"
                      value="Hypotonia"
                      color="green"
                    ></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="dForm.present.reflexes"
                    :items="reflexes"
                    label="Reflexes:"
                    :color="color"
                    item-color="success"
                    multiple
                  >
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <div class="pa-2">
                        <v-textarea
                          :color="color"
                          auto-grow
                          rows="1"
                          outlined
                          v-model="tempref"
                          label="Others"
                          placeholder="...specify other reflexes."
                        >
                        </v-textarea>
                        <v-btn @click="reflexes.push(tempref); tempref = ''">Add</v-btn>
                      </div>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-radio-group dense v-model="dForm.present.neckstiff" row>
                    <span class="mr-3">Neck Stiffness:</span>
                    <v-radio label="Yes" value="Yes" color="green"></v-radio>
                    <v-radio label="No" value="No" color="green"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-radio-group dense v-model="dForm.present.cranialnerve" row>
                    <span class="mr-3">Cranial Nerves :</span>
                    <v-radio
                      label="Normal"
                      value="Normal"
                      color="green"
                    ></v-radio>
                    <v-radio
                      label="Abnormal"
                      value="Abnormal"
                      color="green"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-row >

              <h4 class="mt-10">PREVIOUS TREATMENT</h4>
              <v-radio-group dense v-model="dForm.present.previous" row>
                <span class="mr-3">Any malaria drug given at home:</span>
                <v-radio label="Yes" value="Yes" color="green"></v-radio>
                <v-radio label="No" value="No" color="green"></v-radio>
              </v-radio-group>

              <v-row>
                <v-col cols="12" sm="5" md="8">
                  <p class="mr-3"></p>
                  <v-select
                    :items="setupData.priordrug"
                    label="Drugs used prior to presentation:"
                    v-model="dForm.present.priordrug"
                    :color="color"
                    item-color="success"
                    multiple
                  >
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <div class="pa-2">
                        <v-textarea
                          v-model="tempPrior"
                          :color="color"
                          auto-grow
                          rows="1"
                          outlined
                          label="Specify"
                          placeholder="...specify other drugs prior."
                        >
                        </v-textarea>
                        <v-btn @click="setupData.priordrug.push(tempPrior)">Add</v-btn>
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="10">
                  <v-select
                    :items="setupData.placetreated"
                    label="Places where the child treated before coming here:"
                    v-model="dForm.present.placetreated"
                    :color="color"
                    item-color="success"
                    multiple
                  >
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <div class="pa-2">
                        <v-textarea
                        disabled
                          v-model="dForm.present.placetreated"
                          :color="color"
                          auto-grow
                          rows="1"
                          outlined
                          label="Specify"
                          placeholder="...Other places."
                        >
                        </v-textarea>
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-window-item>

          
          </v-container>
        </v-window>
      </v-card>

      <v-card-actions>
        <v-btn color="error" small> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="saveChanges"
          :loading="loading"
          small
          color="green lighten-1"
        >
          Save and Continue
        </v-btn>
      </v-card-actions>
    </v-stepper-content>

    <v-dialog v-model="dialog" max-width="400">
      <div class="dia">
        <p>Add Custom Illness</p>
        <v-text-field style="width:100%" v-model="ill.complaint" outlined label="Compliants">
        </v-text-field>
        <v-text-field type="number" style="width:100%" v-model="ill.duration" outlined label="Duration (Days)">
        </v-text-field>

        <v-btn :loading="loading" small @click="AddIllNess" class="">Add Illness</v-btn>
      </div>

    </v-dialog>
  </div>
</template>

<script>
import { UpdateADoc } from '@/Auth/index';
import { snackbar } from '@/main.js';

export default {
  name: 'PresentTreat',

  props: ['patient', 'patientData', 'hosp_no'],
  data() {
    return {
      enabled: false,
      tempref: '',
      window: 0,
      color: '#024202',
      cardColor: '#f0f7f0',
      loading: false,
      select: 'Fever',
      buttons: [1, 2],
      powers: [1, 2, 3, 4, 5],
      tempPrior: '',
      illness: [
        { label: 'Fever', value: { complaint: 'Fever', duration: 0 } },
        {
          label: 'Chills/Rigors',
          value: { complaint: 'Chills/Rigors', duration: 0 },
        },
        {
          label: 'Loss of Appetite',
          value: { complaint: 'Loss of Appetite', duration: 0 },
        },
        { label: 'Vomiting ', value: { complaint: 'Vomiting', duration: 0 } },
        { label: 'Diarrhoea', value: { complaint: 'Diarrhoea', duration: 0 } },
        {
          label: 'Yellowness of  the eyes',
          value: { complaint: 'Yellowness of  the eyes', duration: 0 },
        },
        { label: 'Pallor', value: { complaint: 'Pallor', duration: 0 } },
        { label: 'Cough', value: { complaint: 'Cough', duration: 0 } },
        { label: 'Snoring', value: { complaint: 'Snoring', duration: 0 } },
        {
          label: 'Sore Throat',
          value: { complaint: 'Sore Throat', duration: 0 },
        },
        { label: 'Wheezing', value: { complaint: 'Wheezing', duration: 0 } },
        {
          label: 'Chest Pain',
          value: { complaint: 'Chest Pain', duration: 0 },
        },
        { label: 'Fatigue', value: { complaint: 'Fatigue', duration: 0 } },
        {
          label: 'Weight Loss',
          value: { complaint: 'Weight Loss', duration: 0 },
        },
        {
          label: 'Easy Fatigability',
          value: { complaint: 'Easy Fatigability', duration: 0 },
        },
        {
          label: 'Muscle Weakness',
          value: { complaint: 'Muscle Weakness', duration: 0 },
        },
        { label: 'Diplopia', value: { complaint: 'Diplopia', duration: 0 } },
        { label: 'Dizziness', value: { complaint: 'Dizziness', duration: 0 } },
        { label: 'Headache', value: { complaint: 'Headache', duration: 0 } },
        {
          label: 'Impaired Vision',
          value: { complaint: 'Impaired Vision', duration: 0 },
        },
        {
          label: 'Neck Stiffness',
          value: { complaint: 'Neck Stiffness', duration: 0 },
        },
        {
          label: 'Abdominal Distension',
          value: { complaint: 'Abdominal Distension', duration: 0 },
        },
        {
          label: 'Abdomina Pain',
          value: { complaint: 'Abdomina Pain', duration: 0 },
        },
        {
          label: 'Abdomina Mass',
          value: { complaint: 'Abdomina Mass', duration: 0 },
        },
        {
          label: 'Bloody Stool',
          value: { complaint: 'Bloody Stool', duration: 0 },
        },
        { label: 'Nausea', value: { complaint: 'Nausea', duration: 0 } },
        {
          label: 'Difficult / Fast breathing',
          value: { complaint: 'Difficult / Fast breathing', duration: 0 },
        },
        {
          label: 'Prostration / Lethargy',
          value: { complaint: 'Prostration / Lethargy', duration: 0 },
        },
        {
          label: 'Body pains / aches',
          value: { complaint: 'Body pains / aches', duration: 0 },
        },
        {
          label: 'Irritability',
          value: { complaint: 'Irritability', duration: 0 },
        },
        { label: 'Coma', value: { complaint: 'Coma', duration: 0 } },
        {
          label: 'Body Swelling/Oedema ',
          value: { complaint: 'Body Swelling/Oedema', duration: 0 },
        },
        { label: 'Bone Pain', value: { complaint: 'Bone Pain', duration: 0 } },
        {
          label: 'Jaw Swelling',
          value: { complaint: 'Jaw Swelling', duration: 0 },
        },
        {
          label: 'Joint Point',
          value: { complaint: 'Joint Point', duration: 0 },
        },
        { label: 'Paralysis', value: { complaint: 'Paralysis', duration: 0 } },
        {
          label: 'Reduced urine frequency',
          value: { complaint: 'Reduced urine frequency', duration: 0 },
        },
        {
          label: 'Reduced Urine volume',
          value: { complaint: 'Reduced Urine volume', duration: 0 },
        },
        {
          label: 'Passage of dark urine',
          value: { complaint: 'Passage of dark urine', duration: 0 },
        },
        {
          label: 'Bedwetting',
          value: { complaint: 'Bedwetting', duration: 0 },
        },
        {
          label: 'Bloody Urine',
          value: { complaint: 'Bloody Urine', duration: 0 },
        },
        {
          label: 'Difficulty with Urination',
          value: { complaint: 'Difficulty with Urination', duration: 0 },
        },
        {
          label: 'Painful Urination',
          value: { complaint: 'Painful Urination', duration: 0 },
        },
        {
          label: 'Suprapubic Pain',
          value: { complaint: 'Suprapubic Pain', duration: 0 },
        },
        { label: 'Leg Ulcer', value: { complaint: 'Leg Ulcer', duration: 0 } },
        { label: 'Rashes', value: { complaint: 'Rashes', duration: 0 } },
        {
          label: 'Severe trauma e.g. crush injury, burns',
          value: {
            complaint: 'Severe trauma e.g. crush injury, burns',
            duration: 0,
          },
        },
      ],
      setupData: {
        priordrug: [
          'Paracetamol',
          'quine / Nivaquine',
          'Camoquine',
          'Septrin',
          'Sulphadoxine / Pyrimethamine',
          'Native medication',
          'Artemisinin derivatives',
          '	Quinine',
        ],
        placetreated: [
          '	Private hospital',
          'Health Centre',
          'Chemist',
          'Traditional healer',
          'Church',
        ],
      },
      reflexes: ['Normal', 'Increased', 'Reduced'],

      dForm: {
        present: {
          illness: [
            { complaint: 'Fever', duration: 0, isActive: false },
            { complaint: 'Chills/Rigors', duration: 0, isActive: false },
            { complaint: 'Loss of Appetite', duration: 0, isActive: false },
            { complaint: 'Vomiting', duration: 0, isActive: false },
            { complaint: 'Diarrhoea', duration: 0, isActive: false },
            { complaint: 'Abdominal Pain', duration: 0, isActive: false },
            {
              complaint: 'Loss of consciousness',
              duration: 0,
              isActive: false,
            },
            {
              complaint: 'Yellowness of the eyes',
              duration: 0,
              isActive: false,
            },
            { complaint: 'Pallor', duration: 0, isActive: false },
            { complaint: 'Cough', duration: 0 },
            {
              complaint: 'Difficult/Fast breathing',
              duration: 0,
              isActive: false,
            },
            { complaint: 'Prostration/Lethargy', duration: 0, isActive: false },
            { complaint: 'Body pains/aches', duration: 0, isActive: false },
            { complaint: 'Irritability', duration: 0, isActive: false },
            { complaint: 'Coma', duration: 0, isActive: false },
            { complaint: 'Body Swelling', duration: 0, isActive: false },
            {
              complaint: 'Reduced urine frequency',
              duration: 0,
              isActive: false,
            },
            { complaint: 'Reduced Urine volume', duration: 0 },
            {
              complaint: 'Severe trauma e.g crush injury, burns',
              duration: 0,
              isActive: false,
            },
            {
              complaint: 'Blood transfusion in the last',
              duration: 0,
              isActive: false,
            },
          ],
          convulsion: { episode: 0, seizure: '', status: false },
          episode: { howmany: 0, status: false },
          priordrug: [],
          placetreated: [],
          power: { rul: '', lul: '', rll: '', lll: '' },
          reflexes: '',
        },
      },
      ill: {
       
      },
      dialog: false
    };
  },
  created() {
    if (this.hosp_no) {
      setTimeout(() => {
        this.dForm = this.patientData;
      }, 1000);
    }
  },

  methods: {

    AddIllNess(){
      this.dForm.present.illness.push({...this.ill,  isActive: true})
      this.saveChanges()
      this.dialog = false
      this.ill = {}
    },
    async saveChanges() {
      this.loading = true;
      try {
        await UpdateADoc.update(
          'patients',
          this.patientData.hosp_no,
          this.dForm
        );
        this.loading = false;
        snackbar.$emit('displaySnackbar', {
          msg: 'Chnages Saved',
          color: 'success',
        });
      } catch (err) {
      console.log(err)
        this.loading = false
        snackbar.$emit('displaySnackbar', {
          msg: 'Error Occured',
          color: 'error',
        });
      }
    },
    findIllness(id) {
      let illness = this.dForm.present.illness[id];
      if (illness) {
        return illness.complaint;
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped>
.compliants{
  background: white !important;
  border-radius: 8px;
  padding: 10px;
}

.dia{
  background: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
</style>
