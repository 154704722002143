<template>
  <div>
    <v-stepper-content step="5">
      <h3 class="text-center mb-2">Follow Up</h3>
      <v-card color="#f0f7f0" class="pa-0 ma-0">
        <v-card-title>
          <v-row class="d-flex justify-center align-center pa-3">
            <v-select
              :items="followUp"
              @change="formChange"
              v-model="selectedFollowUp"
              filled
              label="Investigation Result"
            >
            </v-select>
          </v-row>
        </v-card-title>

        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="dform.date"
                type="date"
                label="Date: "
                color=" "
              ></v-text-field>
              <v-text-field
                v-model="dform.bf"
                label="BF for MP Parasite count  (per ul): "
                color=" "
              >
              </v-text-field>
              <v-text-field
                label="PVC on admission: "
                v-model="dform.pvc"
                color=" "
              ></v-text-field>
              <v-text-field
                label="WCB + diff. (c/mm3): "
                v-model="dform.wcb"
                color=" "
              ></v-text-field>
              <v-text-field
                label="Platelet (c/mm3): "
                v-model="dform.platelet"
                color=" "
              ></v-text-field>
              <v-text-field
                label="HB Genotype: "
                v-model="dform.hbGenotype"
                color=" "
              ></v-text-field>
              <v-text-field
                label="Sodium (mmol/l): "
                v-model="dform.sodium"
                color=" "
              ></v-text-field>
              <v-text-field
                label="Potassium (mmol/l): "
                v-model="dform.potassium"
                color=" "
              ></v-text-field>
              <v-text-field
                label="chloride (mmol/l): "
                v-model="dform.chloride"
                color=" "
              ></v-text-field>
              <v-text-field
                label="Bicorbonate (mmol/l): "
                v-model="dform.bicorbonate"
                color=" "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="dform.urea"
                label="Urea (mg/dl): "
                color=" "
              ></v-text-field>
              <v-text-field
                v-model="dform.creatinine"
                label="Creatinine (ὕmol/l): "
                color=" "
              ></v-text-field>

              <v-text-field
                label="G6PD Screening: "
                v-model="dform.g6pdScreening"
                color=" "
              ></v-text-field>
              <v-text-field
                label="G6PD Result: "
                color=" "
                v-model="dform.g6pdResult"
              ></v-text-field>
              <v-text-field
                label="Haemoglobin type: "
                v-model="dform.haemoglobinType"
                color=" "
              ></v-text-field>
              
              <v-combobox
                :value="DestructUniralysis"
                :items="DestructUniralysis"
                @click="Open"
                readonly
                label="Urinalysis: "
                multiple
                outlined
                dense
              ></v-combobox>
              <v-text-field
                label="Urine microscopy: "
                color=" "
                v-model="dform.urineMicroscopy"
              ></v-text-field>
              <v-text-field
                label="Blood culture: "
                color=" "
                v-model="dform.bloodCulture"
              ></v-text-field>
              <v-text-field
                label="CXR findings: "
                color=" "
                v-model="dform.cxrFindings"
              ></v-text-field>
              <v-text-field
                label="PVC at discharge: "
                color=" "
                v-model="dform.pvcDischarge"
              ></v-text-field>
              <v-text-field
                label="Serial Blood Pressure: "
                color=" "
                v-model="dform.seriealBloodPressure"
              >
              </v-text-field>
            </v-col>
            <v-card-actions style="width: 100%">
              <v-btn color="error" small> Cancel </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                small
                @click="saveChanges"
                :loading="loading"
                color="green lighten-1"
              >
                Save and Continue
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-stepper-content>

    <v-dialog v-model="dialog" max-width="500">
      <div class="dia">
        <p>Uninalysis Parameters</p>
        <v-text-field
          style="width: 100%"
          v-model="urine.glucose"
          outlined
          label="Glucose"
        >
        </v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.asorbicAcid"
          outlined
          label="Asorbic Acid"
        >
        </v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.ketones"
          outlined
          label="Ketones"
        >
        </v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.protien"
          outlined
          label="Protien"
        >
        </v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.phvalue"
          outlined
          label="PhValue"
        >
        </v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.blood"
          outlined
          label="Blood"
        >
        </v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.nitrite"
          outlined
          label="Nitrite"
        >
        </v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.leucocytes"
          outlined
          label="Leucocytes"
        >
        </v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.bilirubin"
          outlined
          label="Bilirubin"
        ></v-text-field>
        <v-text-field
          style="width: 100%"
          v-model="urine.urobilinogen"
          outlined
          label="Urobilinogen"
        ></v-text-field>
        <v-btn :loading="loading" small @click="SaveUrineAlysis" class=""
          >Save Illness</v-btn
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { UpdateADoc } from '@/Auth/index';
import { snackbar } from '@/main.js';

export default {
  components: {},
  name: 'FollowUp',
  props: ['patient', 'patientData', 'hosp_no'],

  data() {
    return {
      dialog: false,
      selectedFollowUp: 'onAdmission',
      loading: false,
      followUp: ['onAdmission', 'week2', 'week4', 'week8', 'week12'],
      dform: {
        urinalysis: {},
      },
      urine: {},
    };
  },
  methods: {
    Open() {
      this.dialog = true;
      this.urine = this.dform.urinalysis;
    },
    SaveUrineAlysis() {
      this.dform.urinalysis = this.urine;
      this.dialog = false;
    },
    async saveChanges() {
      this.loading = true;

      try {
        if (this.selectedFollowUp == 'onAdmission') {
          await UpdateADoc.update('patients', this.patient.hosp_no, {
            followUpOnAdmission: this.dform,
          });
        }
        if (this.selectedFollowUp == 'week2') {
          await UpdateADoc.update('patients', this.patient.hosp_no, {
            followUpWeekTwo: this.dform,
          });
        }
        if (this.selectedFollowUp == 'week4') {
          await UpdateADoc.update('patients', this.patient.hosp_no, {
            followUpWeekFour: this.dform,
          });
        }
        if (this.selectedFollowUp == 'week8') {
          await UpdateADoc.update('patients', this.patient.hosp_no, {
            followUpWeekEight: this.dform,
          });
        }
        if (this.selectedFollowUp == 'week12') {
          await UpdateADoc.update('patients', this.patient.hosp_no, {
            followUpWeekTwelve: this.dform,
          });
        }
        snackbar.$emit('displaySnackbar', {
          msg: 'Chnages Saved',
          color: 'success',
        });
        this.loading = false;
      } catch (err) {
        err;
        snackbar.$emit('displaySnackbar', {
          msg: 'Error Occured',
          color: 'error',
        });
        this.loading = false;
      }
    },

    formChange() {
      if (this.hosp_no || this.patient.hosp_no) {
        if (this.selectedFollowUp == 'onAdmission') {
          this.dform = this.patientData.followUpOnAdmission;
        }
        if (this.selectedFollowUp == 'week2') {
          this.dform = this.patientData.followUpWeekTwo;
        }
        if (this.selectedFollowUp == 'week4') {
          this.dform = this.patientData.followUpWeekFour;
        }
        if (this.selectedFollowUp == 'week8') {
          this.dform = this.patientData.followUpWeekEight;
        }
        if (this.selectedFollowUp == 'week12') {
          this.dform = this.patientData.followUpWeekTwelve;
        }
        // this.dform = this.patientData.
      }
    },
  },
  computed: {
    DestructUniralysis() {
      let tempdata = [];
      for (var k in this.dform.urinalysis) {
        let newdata = k + ": "+ this.dform.urinalysis[k]
        tempdata.push(newdata.toString())
      }

      return tempdata
    },
  },
  created() {
    if (this.hosp_no) {
      setTimeout(() => {
        if (this.selectedFollowUp == 'onAdmission') {
          this.dform = this.patientData.followUpOnAdmission;
        }
        if (this.selectedFollowUp == 'week2') {
          this.dform = this.patientData.followUpWeekTwo;
        }
        if (this.selectedFollowUp == 'week4') {
          this.dform = this.patientData.followUpWeekFour;
        }
        if (this.selectedFollowUp == 'week8') {
          this.dform = this.patientData.followUpWeekEight;
        }
        if (this.selectedFollowUp == 'week12') {
          this.dform = this.patientData.followUpWeekTwelve;
        }
      }, 1000);
    }
  },
};
</script>

<style scoped>
.dia {
  background: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
</style>
