<template>
  <div>
    <v-stepper-content step="1">
      <h3 class=" text-center mb-2">Account Opening</h3>
      <v-card class="mb-7" :color="cardColor">
        <v-container>
          <v-form ref="form">
            <v-text-field label="Hospital Number:" :color="color" :readonly="!editHosp_no" v-model="dForm.hosp_no"
              :rules="[rules.required]">
            </v-text-field>
            <v-text-field label="Name:" :color="color" v-model="dForm.name" :rules="[rules.required]">
            </v-text-field>
            <v-radio-group v-model="dForm.location" dense row>
              <span class="mr-3">Location: </span>
              <v-radio label="Ado Annex" value="ADO" color="green"></v-radio>
              <v-radio label="Ido" value="IDO" color="green"></v-radio>
            </v-radio-group>
            <v-radio-group v-model="dForm.entry_point" dense row>
              <span class="mr-3">Entry Point: </span>
              <v-radio label="EPU" value="EPU" color="green"></v-radio>
              <v-radio label="CHOP" value="CHOP" color="green"></v-radio>
            </v-radio-group>
           

            <v-text-field
            v-if="dForm.entry_point == 'EPU'"
              type="date"
              label="Date of Admission:"
              :color="color"
              v-model="dForm.admissionDate"
            >
            </v-text-field>
            <v-text-field
            v-if="dForm.entry_point == 'EPU'"
              type="date"
              label="Date of Discharge:"
              :color="color"
              v-model="dForm.dischargeDate"
            >
            </v-text-field>
            <v-text-field
            v-if="dForm.entry_point == 'EPU'"
            type="number"
              label="Admission Duration (Days):"
              :color="color"
              v-model="dForm.admissionDuration"
            >
            </v-text-field>
          </v-form>
        </v-container>
      </v-card>
      <v-card-actions>
        <v-btn small color="error">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="patient.hosp_no" small color="green lighten-1" @click="saveChanges()" :loading="loading">
          Save Changes
        </v-btn>
        <v-btn v-else @click="createAccount()" small color="green lighten-1" :loading="loading">
          Create Account
        </v-btn>
      </v-card-actions>
    </v-stepper-content>


  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  docUpate,
  UpdateADoc,
} from '@/Auth/index';
// import { onSnapshot } from '@/Auth/index';
import {
  bus,
  snackbar,
} from '@/main.js';

export default {
  name: "EpuAccopen",
  props: ['patient', 'patientData', 'hosp_no'],

  data() {
    return {
      color: '#024202',
      loading: false,
      cardColor: '#f0f7f0',
      rules: { required: value => !!value || 'Please fill this field.' },
      edit: { studyno: false, hosp_no: false, study_entry_date: false },
      editHosp_no: true,
      dForm: {
        studyno: '',
        study_entry_date: '',
        admissionDate: '',
        dischargeDate: '',
        admissionDuration: '',
        location: '',
        hosp_no: '',
        createdate: Date.now(),
        createdYear: new Date().getFullYear() ,
        entry_Point: '',
        demo_data: { address: '', phoneno: '', age: '', dob: '', sex: '', tribe: '', marital: { married: '' }, matEdu: '', patEdu: '', matOccup: '', patOccup: '' },
        present: { illness: [
          { complaint: 'Fever', duration: 0, isActive: false },
              { complaint: 'Chills/Rigors', duration: 0, isActive: false },
              { complaint: 'Loss of Appetite', duration: 0, isActive: false },
              { complaint: 'Vomiting', duration: 0, isActive: false },
              { complaint: 'Diarrhoea', duration: 0, isActive: false },
              { complaint: 'Abdominal Pain', duration: 0, isActive: false },
              { complaint: 'Loss of consciousness', duration: 0, isActive: false },
              { complaint: 'Yellowness of the eyes', duration: 0, isActive: false },
              { complaint: 'Pallor', duration: 0, isActive: false },
              { complaint: 'Cough', duration: 0 },
              { complaint: 'Difficult/Fast breathing', duration: 0, isActive: false },
              { complaint: 'Prostration/Lethargy', duration: 0, isActive: false },
              { complaint: 'Body pains/aches', duration: 0, isActive: false },
              { complaint: 'Irritability', duration: 0, isActive: false },
              { complaint: 'Coma', duration: 0, isActive: false },
              { complaint: 'Body Swelling', duration: 0, isActive: false },
              { complaint: 'Reduced urine frequency', duration: 0, isActive: false },
              { complaint: 'Reduced Urine volume', duration: 0 },
              { complaint: 'Severe trauma e.g crush injury, burns', duration: 0, isActive: false },
              { complaint: 'Blood transfusion in the last', duration: 0, isActive: false }
        ], convulsion: { episode: 0, seizure: '', status: false }, episode: { howmany: 0, status: false }, priordrug: [], placetreated: [], power: { rul: '', lul: '', rll: '', lll: '' } },
        outcome: {},
        followUpOnAdmission: {},
        followUpWeekTwo: {},
        followUpWeekFour: {},
        followUpWeekEight: {},
        followUpWeekTwelve: {}

      }
    }
  },

  computed: {
    editSteppers() {
      return true
    },
    activePatient() {
      return this.$store.currPatient
    },
    ...mapState(['currPatient']),


  },

  created() {
    if (this.hosp_no) {
      setTimeout(() => {
        this.editHosp_no = false
        this.dForm = this.patientData
      }, 1000)
    }

  },



  methods: {
    async createAccount() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await docUpate.update('patients', this.dForm.hosp_no, this.dForm
          )
          this.loading = false
          bus.$emit("patient", { hosp_no: this.dForm.hosp_no });
          snackbar.$emit("displaySnackbar", { msg: 'Account created Succesfully', color: 'success' })

        } catch (err) {
          err
          snackbar.$emit("displaySnackbar", { msg: "Error Eccoured", color: "error" });
        }

      }
    },
    async saveChanges() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          await UpdateADoc.update("patients", this.dForm.hosp_no, this.dForm)
          this.loading = false
          console.log('Saved')
          snackbar.$emit("displaySnackbar", { msg: 'Changes Saved', color: 'success' })
        } catch (err) {
          console.log(err)
          snackbar.$emit("displaySnackbar", { msg: 'Account created Succesfully', color: 'success' })
        }

      }
    }
  },

}
</script>
