<template>
  <div>
    <v-stepper v-model="e1" non-linear class="mt-6">
      <EpuStepper />
      <v-stepper-items>
        <EpuAccopen :patient="currPatient" :patientData="patientData" :hosp_no="hosp_no" />
        <DemoData :patient="currPatient" :patientData="patientData" :hosp_no="hosp_no" />
        <PresentTreat :patient="currPatient" :patientData="patientData" :hosp_no="hosp_no" />
        <TreatOutcome :patient="currPatient" :patientData="patientData" :hosp_no="hosp_no" />
        <FollowUp :patient="currPatient" :patientData="patientData" :hosp_no="hosp_no" />
      </v-stepper-items>
    </v-stepper>
  

  </div>
</template>

<script>

import { onSnapshot, selectedUser } from '@/Auth/index';
import DemoData from '@/components/EPU/DemoData.vue';
import EpuStepper from '@/components/EPU/EpuStepper.vue';
import EpuAccopen from '@/components/EPU/EpuAccopen.vue';
import PresentTreat from '@/components/EPU/PresentTreat.vue';
import TreatOutcome from '@/components/EPU/TreatOutcome.vue';
import FollowUp from '@/components/EPU/FollowUp.vue';
import { bus } from "@/main.js";
export default {
  name: "Epunewpat",


  components: {
    EpuStepper,
    EpuAccopen,
    DemoData,
    PresentTreat,
    TreatOutcome,
    FollowUp
  },

  props: {
    editUser: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      e1: 1,
      currPatient: {},
      color: '#024202',
      loading: false,
      cardColor: '#f0f7f0',
      snackMsg: "",
      snackColor: "success",
      patientData: {},
      hosp_no: ''

    }
  },
  methods: {
    

    getSelectedPatient() {
      onSnapshot(selectedUser("patients", this.currPatient.hosp_no), (doc) => {
        this.patientData = doc.data()
      })

    },

  },
  created() {
    bus.$on("patient", (event) => {
      this.currPatient = event
      this.getSelectedPatient()

    });
     if (this.editUser) {
      if (this.editUser.hosp_no) {
        this.hosp_no = this.editUser.hosp_no
        this.currPatient.hosp_no = this.editUser.hosp_no
        this.getSelectedPatient()
      }

    }
  },
  mounted() {
    if (this.currPatient.hosp_no) {
      this.getSelectedPatient()
    }
   
  },


}
</script>

<style lang="scss" scoped>

</style>