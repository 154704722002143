<template>
  <div>
    <v-stepper-content step="2">
      <h3 class="text-center mb-2">Demographics Data</h3>
      <v-card class="mb-8 pa-3" :color="cardColor">
        <v-row>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="dForm.demo_data.address"
              :color="color"
              auto-grow
              rows="1"
              label="Address:"
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Contact Phone Number (Father/Mother):"
              v-model="dForm.demo_data.phoneno"
              type="phone"
              :color="color"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="d-flex ">
              <v-select
                :items="['Days', 'Weeks', 'Months', 'Years']"
                label="Select Age Type"
                v-model="dForm.demo_data.ageUnit"
              >
              </v-select>
              <v-text-field
                label="Age (as at last birthday):"
                type="number"
                v-model="dForm.demo_data.age"
                :color="color"
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              type="date"
              label="Date of Birth:"
              :color="color"
              v-model="dForm.demo_data.dob"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              type="number"
              label="Number of children in the family:"
              :color="color"
              v-model.trim="dForm.demo_data.no_of_children"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              type="number"
              label="Position of patient in the family:"
              :color="color"
              v-model.trim="dForm.demo_data.child_position"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-radio-group dense v-model="dForm.demo_data.sex" row>
              <span class="mr-3">Sex: </span>
              <v-radio label="Male" value="Male" color="green"></v-radio>
              <v-radio label="Female" value="Female" color="green"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <v-radio-group dense v-model="dForm.demo_data.tribe" row>
              <span class="mr-3">Tribe: </span>
              <v-radio label="Yoruba" value="Yoruba" color="green"></v-radio>
              <v-radio label="Hausa" value="Hausa" color="green"></v-radio>
              <v-radio label="Igbo" value="Igbo" color="green"></v-radio>
              <v-radio label="Others" value="Others" color="green"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              :items="setupData.marital"
              label="Parent's Marital Status:"
              v-model="dForm.demo_data.marital"
              :color="color"
              item-color="success"
            ></v-select>
          </v-col>

          <div>
            <v-row>
              <v-col v-if="dForm.demo_data.marital == 'Married'">
                <v-radio-group dense v-model="dForm.demo_data.married" row>
                  <span class="mr-3">Family Structure:</span>
                  <v-radio
                    label="Monogamous"
                    value="Monogamous"
                    color="green"
                  ></v-radio>
                  <v-radio
                    label="Polygamous"
                    value="Polygamous"
                    color="green"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>

          <v-col cols="12" sm="6">
            <v-select
              :items="setupData.education"
              label="Education Status of Mother:"
              v-model="dForm.demo_data.matEdu"
              :color="color"
              item-color="success"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="setupData.education"
              label="Education Status of Father:"
              v-model="dForm.demo_data.patEdu"
              :color="color"
              item-color="success"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="setupData.occupation"
              label="Occupation Of Mother:"
              v-model="dForm.demo_data.matOccup"
              :color="color"
              item-color="success"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="setupData.occupation"
              label="Occupation of Father:"
              v-model="dForm.demo_data.patOccup"
              :color="color"
              item-color="success"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>

      <v-card-actions>
        <v-btn color="error" small> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="saveChanges"
          :loading="loading"
          small
          color="green lighten-1"
        >
          Save and Continue
        </v-btn>
      </v-card-actions>
    </v-stepper-content>
  </div>
</template>

<script>
import { UpdateADoc } from '@/Auth/index';
import { snackbar } from '@/main.js';

export default {
  name: 'DemoData',
  props: ['patient', 'patientData', 'hosp_no'],

  data: () => ({
    agetype: '',
    age: 0,
    color: '#024202',
    cardColor: '#f0f7f0',
    loading: false,
    setupData: {
      marital: [
        { value: '', text: 'Select...', disabled: true },
        'Not Yet Married',
        'Married',
        'Divorced',
        'Separated',
        'Widowed',
      ],
      education: [
        { value: '', text: 'Select...', disabled: true },
        'Tertiary',
        'Secondary',
        'Primary',
        'Koranic',
        'Nil',
      ],
      occupation: [
        { value: '', text: 'Select...', disabled: true },
        'Nil',
        'Senior public servant, professional, manager,contractor, large scale trader',
        'Intermediate grade, public servant, senior school teachers',
        'Junior school teacher, driver, artisan',
        'Petty trader, labourer, messenger, similar grades',
        'Unemployment, fulltime house wife, student, subsistence farmer',
      ],
    },
    dForm: {
      demo_data: {
        address: '',
        phoneno: '',
        ageUnit: '',
        age: '',
        dob: '',
        sex: '',
        tribe: '',
        marital: { married: '' },
        matEdu: '',
        patEdu: '',
        matOccup: '',
        patOccup: '',
      },
    },
  }),
  created() {
    if (this.hosp_no) {
      setTimeout(() => {
        this.dForm = this.patientData;
      }, 1000);
    }
  },

  methods: {
    async saveChanges() {
      this.loading = true;
      this.dForm.age = ''
      try {
        await UpdateADoc.update(
          'patients',
          this.patientData.hosp_no,
          this.dForm
        );
        this.loading = false;
        snackbar.$emit('displaySnackbar', {
          msg: 'Changes Saved',
          color: 'success',
        });
      } catch (err) {
        err;
        snackbar.$emit('displaySnackbar', {
          msg: 'Error Occured',
          color: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
